
























import { Component, Vue, Prop } from "vue-property-decorator";
import { GetZhibiao } from "@/request/analysis";
@Component({})
export default class ZhibiaoSelect extends Vue {
  @Prop()
  private config: any;
  private fenzus: any[] = [];
  private configChange() {
    this.$emit("update:config", this.config);
  }
  /**
   * @description 获得描述性分析的指标
   */
  private fetchZhibiao() {
    if (!this.config.table_id) {
      return;
    }
    if (this.config.way === "症状特异性分析") {
      this.fenzus = ["中医证型"];
      return;
    }
    const params: any = {
      params: {
        table_id: this.config.table_id,
      },
    };
    GetZhibiao(this, params).then((data: any) => {
      this.fenzus = data;
    });
  }
  /**
   * @description 初始化
   */
  private init() {
    this.$set(this.config, "分组变量", "");
    this.fetchZhibiao();
  }
  /**
   * @description 执行初始化
   */
  private mounted() {
    this.init();
  }
}
