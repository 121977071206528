var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"root",staticClass:"reportContent commonReport",attrs:{"id":"suizhengyongyaoguilv"},on:{"scroll":_vm.handleScroll}},[_c('div',{staticClass:"part"},[_c('div',{staticClass:"title"},[_vm._v("变量设置")]),_c('div',{staticClass:"line"},[_vm._v("分析变量："+_vm._s(_vm.result["分析变量"]))]),_c('div',{staticClass:"line"},[_vm._v("分组变量："+_vm._s(_vm.result["分组变量"]))])]),_c('div',{staticClass:"part"},[_c('div',{staticClass:"title"},[_vm._v("分析方法")]),_c('div',{staticClass:"line"},[_vm._v(" "+_vm._s(_vm.result["分析方法"])+" ")])]),_c('div',{staticClass:"part",staticStyle:{"border-top":"1px dashed #d0d0d0","padding-top":"20px"}},[_c('div',{staticClass:"title"},[_vm._v("结果解释")]),_c('div',{staticClass:"guilvPart"},[_c('div',{staticClass:"echartsWrap jiajianguilv"},[_c('div',{staticClass:"partTitle"},[_vm._v("随症加减规律")]),_c('div',{staticClass:"control"},[_c('span',{staticClass:"zhichidu"},[_vm._v("最小支持度")]),_c('el-input',{staticClass:"zhichiduInput",attrs:{"type":"number","min":"0","max":"100"},model:{value:(_vm.config['支持度']),callback:function ($$v) {_vm.$set(_vm.config, '支持度', $$v)},expression:"config['支持度']"}}),_c('span',{staticClass:"baifenbi"},[_vm._v("%")]),_c('span',{staticClass:"zhixindu"},[_vm._v("最小置信度")]),_c('el-input',{staticClass:"zhixinduInput",attrs:{"type":"number","min":"0","max":"100"},model:{value:(_vm.config['置信度']),callback:function ($$v) {_vm.$set(_vm.config, '置信度', $$v)},expression:"config['置信度']"}}),_c('span',{staticClass:"baifenbi"},[_vm._v("%")]),_c('button',{staticClass:"refreshBtn",on:{"click":_vm.conditionChange}},[_c('img',{attrs:{"src":"/analysis/刷新.png","alt":""}}),_c('span',[_vm._v("刷新")])])],1),(
            _vm.guilvLoaded &&
            _vm.result['随症加减规律'] &&
            _vm.result['随症加减规律']['nodes'] &&
            _vm.result['随症加减规律']['nodes'].length > 0
          )?_c('VueEcharts',{staticClass:"guilv",attrs:{"option":_vm.guilvOption}}):_vm._e(),(
            !_vm.result['随症加减规律'] ||
            !_vm.result['随症加减规律']['nodes'] ||
            _vm.result['随症加减规律']['nodes'].length == 0
          )?_c('Empty',{attrs:{"text":'暂无数据!'}}):_vm._e()],1),(_vm.result['随症用药规律明细'])?_c('div',{staticClass:"tableWrap pincitongjimingxi"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"partTitle"},[_vm._v(" 随症用药规律明细 "),_c('span',[_vm._v("（共计"+_vm._s(_vm.result["随症用药规律明细"]["count"])+"条，可预览前20条）")])]),_c('button',{staticClass:"exportBtn",on:{"click":function($event){return _vm.downloadGuilv()}}},[_c('img',{attrs:{"src":"/analysis/导出数据.png","alt":""}}),_c('span',[_vm._v("导出数据")])])]),_c('table',{attrs:{"border":"1"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("序号")]),_vm._l((_vm.result['随症用药规律明细']['name']),function(th,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(th)+" ")])})],2)]),_c('tbody',_vm._l((_vm.result['随症用药规律明细']['data']),function(item,itemIndex){return _c('tr',{key:itemIndex},[_c('td',[_vm._v(_vm._s(itemIndex + 1))]),_vm._l((item),function(i,iIndex){return _c('td',{key:iIndex},[_vm._v(_vm._s(i))])})],2)}),0)])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }