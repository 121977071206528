


















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  GetExpertDataTable,
  PostSaveResult,
  Postzhengzhuangqunfenxi,
  Postzhengzhuangteyixingfenxi,
  Postyongyaopeiwufenxi,
  Postyongyaoteyixingfenxi,
  Postsuizhengyongyaoguilv,
} from "@/request/analysis";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import SaveDialog from "../../components/saveDialog.vue";
import DataTableReview from "../../components/data-table-review.vue";
import ZhibiaoSelect from "./zhibiao-select.vue";
import ReportTransfer from "./report-transfer.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    SaveDialog,
    DataTableReview,
    ZhibiaoSelect,
    ReportTransfer,
  },
})
export default class Name extends Vue {
  private config: any = {
    table_id: "",
    way: "症状群分析",
  };
  private tables: any[] = [];
  private ways: any[] = [
    {
      name: "症状群分析",
      explain: "",
    },
    {
      name: "症状特异性分析",
      explain: "",
    },
    {
      name: "用药配伍分析",
      explain: "",
    },
    {
      name: "用药特异性分析",
      explain: "",
    },
    {
      name: "随症用药规律",
      explain: "",
    },
  ];
  private results: any[] = [];
  private cResult: any = {};
  private ifShowSaveDialog: boolean = false;
  private ifShowDataTableReview: boolean = false;
  /**
   * @description 初始化配置
   */
  private initConfig() {
    const config = {
      table_id: this.config.table_id,
      way: this.config.way,
    };
    this.config = config;
  }
  /**
   * @description 获得数据表格
   */
  private fetchTableData() {
    this.config.table_id = "";
    GetExpertDataTable(this).then((data: any) => {
      this.tables = data;
    });
  }
  /**
   * @description 选择的数据变化后
   */
  private tableIdChange() {
    (this.$refs.zhibiaoSelect as any).init();
  }
  /**
   * @description 选择统计方法
   */
  private chooseWay(way: any) {
    this.config.way = way;
    this.initConfig();
  }
  /**
   * @description 开始分析
   */
  private startAnalysis() {
    if (!this.config.table_id) {
      this.$message.warning("请选择一个数据表格！");
      return;
    }
    switch (this.config.way) {
      case "症状群分析":
        this.zhengzhuangqunFenxi();
        break;
      case "症状特异性分析":
        this.zhengzhuangteyixingFenxi();
        break;
      case "用药配伍分析":
        this.yongyaopeiwuFenxi();
        break;
      case "用药特异性分析":
        this.yongyaoteyixingFenxi();
        break;
      case "随症用药规律":
        this.suizhengyongyaoguilv();
        break;
    }
  }
  /**
   * @description 症状群分析
   */
  private zhengzhuangqunFenxi() {
    const load = this.$loading({
      text: "分析中...请稍后",
    });
    const params: any = {
      table_id: this.config.table_id,
      分组变量: this.config["分组变量"],
      is_download: false,
    };
    Postzhengzhuangqunfenxi(this, params)
      .then((data: any) => {
        load.close();
        const result: any = {
          title: "症状群分析",
          report: data,
          _id: new Date().getTime(),
          config: params,
        };
        this.results.push(result);
        this.cResult = this.results[this.results.length - 1];
      })
      .catch(() => {
        load.close();
      });
  }
  /**
   * @description 症状特异性分析
   */
  private zhengzhuangteyixingFenxi() {
    const load = this.$loading({
      text: "分析中...请稍后",
    });
    const params: any = {
      table_id: this.config.table_id,
      is_download: false,
      download_name: this.config["分组变量"],
    };
    Postzhengzhuangteyixingfenxi(this, params)
      .then((data: any) => {
        load.close();
        const result: any = {
          title: "症状特异性分析",
          report: data,
          _id: new Date().getTime(),
          config: params,
        };
        this.results.push(result);
        this.cResult = this.results[this.results.length - 1];
      })
      .catch(() => {
        load.close();
      });
  }
  /**
   * @description 用药配伍分析
   */
  private yongyaopeiwuFenxi() {
    const load = this.$loading({
      text: "分析中...请稍后",
    });
    const params: any = {
      table_id: this.config.table_id,
      分组变量: this.config["分组变量"],
      统计个数: 6,
      其它: true,
      支持度: 20,
      置信度: 20,
      is_download: false,
      download_name: "",
    };
    Postyongyaopeiwufenxi(this, params)
      .then((data: any) => {
        load.close();
        const result: any = {
          title: "用药配伍分析",
          report: data,
          _id: new Date().getTime(),
          config: params,
        };
        this.results.push(result);
        this.cResult = this.results[this.results.length - 1];
      })
      .catch(() => {
        load.close();
      });
  }
  /**
   * @description 用药特异性分析
   */
  private yongyaoteyixingFenxi() {
    const load = this.$loading({
      text: "分析中...请稍后",
    });
    const params: any = {
      table_id: this.config.table_id,
      download_name: this.config["分组变量"],
      is_download: false,
    };
    Postyongyaoteyixingfenxi(this, params)
      .then((data: any) => {
        load.close();
        const result: any = {
          title: "用药特异性分析",
          report: data,
          _id: new Date().getTime(),
          config: params,
        };
        this.results.push(result);
        this.cResult = this.results[this.results.length - 1];
      })
      .catch(() => {
        load.close();
      });
  }
  /**
   * @description 随症用药规律
   */
  private suizhengyongyaoguilv() {
    const load = this.$loading({
      text: "分析中...请稍后",
    });
    const params: any = {
      table_id: this.config.table_id,
      分组变量: this.config["分组变量"],
      支持度: 20,
      置信度: 20,
      is_download: false,
    };
    Postsuizhengyongyaoguilv(this, params)
      .then((data: any) => {
        load.close();
        const result: any = {
          title: "随症用药规律",
          report: data,
          _id: new Date().getTime(),
          config: params,
        };
        this.results.push(result);
        this.cResult = this.results[this.results.length - 1];
      })
      .catch(() => {
        load.close();
      });
  }
  /**
   * @description 改变当前的报告
   */
  private changeResult(result: any) {
    this.cResult = {};
    this.cResult = result;
  }
  /**
   * @description 删除一份报告
   */
  private deleteOneResult(index: number, result: any) {
    if (result === this.cResult) {
      this.results.splice(index, 1);
      if (this.results.length > 0) {
        this.cResult = this.results[0];
      }
    } else {
      this.results.splice(index, 1);
    }
  }
  /**
   * @description 打开保存弹窗
   */
  private openSaveDialog() {
    this.ifShowSaveDialog = true;
  }
  /**
   * @description 保存报告
   */
  private saveResult() {
    const params: any = {
      table_id: this.config.table_id,
      报告名称: this.cResult["报告名称"],
      分类: "专家经验",
      data: this.cResult.report,
    };
    PostSaveResult(this, params).then((data: any) => {
      this.$message.success("保存成功");
      this.ifShowSaveDialog = false;
    });
  }
  /**
   * @description 导出数据
   */
  private exportData(title: any, name: any) {
    if (title === "关联规则") {
      const params: any = this.config;
      params.is_download = true;
      params.download_name = name;
      // PostGuanlianguizefenxi(this, params).then((data: any) => {
      //   GetExportDataTable(data.file_name);
      // });
    }
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.config.way = this.$route.query.type;
    if (this.$route.query) {
      if (this.$route.query.way) {
        this.chooseWay(this.$route.query.way);
      }
    }
    this.fetchTableData();
  }
}
