

































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as _ from "lodash";
import wordcloud from "vue-wordcloud";
import { Postzhengzhuangqunfenxi } from "@/request/analysis";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
    wordcloud,
  },
})
export default class Zhengzhuangqunfenxi extends Vue {
  @Prop()
  private report: any;
  @Prop()
  private config: any;
  private result: any = {};
  private ifShowFloatTab: boolean = false;
  private cloudLoaded: boolean = false;
  private blueColor: any[] = ["#1293D1"];
  private tupuOption: any = {
    series: [
      {
        type: "graph",
        layout: "force",
        force: {
          repulsion: 1000,
        },
        label: {
          show: true,
        },
      },
    ],
  };
  private graphLoaded: boolean = false;
  @Watch("report", { immediate: true, deep: true })
  private reportChange() {
    this.result = this.report;
    this.createCiyun();
    this.createGraph();
  }
  /**
   * @description 监听根部滚动
   */
  private handleScroll() {
    const top = (this.$refs.root as any).scrollTop;
    if (top && top > 200) {
      this.ifShowFloatTab = true;
    } else {
      this.ifShowFloatTab = false;
    }
  }
  /**
   * @description 症状群数据下载
   */
  private downloadZhengzhuangqun() {
    const load = this.$loading({
      text: "下载中...请稍后",
    });
    const params: any = this.config;
    params.is_download = true;
    Postzhengzhuangqunfenxi(this, this.config).then((data: any) => {
      window.open(
        window.API_ROOT + "api/wajue/download?filename=" + data.file_name
      );
      load.close();
    });
  }
  /**
   * @description 生成词云
   */
  private createCiyun() {
    this.cloudLoaded = true;
  }
  /**
   * @description 生成关系图
   */
  private createGraph() {
    this.tupuOption = {
      series: [
        {
          type: "graph",
          layout: "force",
          force: {
            repulsion: 1000,
          },
          label: {
            show: true,
          },
        },
      ],
    };
    this.tupuOption.series[0].data = this.result["症证图谱"]["nodes"];
    this.tupuOption.series[0].categories =
      this.result["症证图谱"]["categories"];
    this.tupuOption.series[0].links = this.result["症证图谱"]["links"];
    this.tupuOption.series[0].symbolSize = (value: any) => {
      return value;
    };
    this.graphLoaded = true;
  }
  /**
   * @description 初始化
   */
  private mounted() {
    // this.result = this.report;
    // this.createCiyun();
    // this.createGraph();
  }
}
