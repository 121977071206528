


















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as _ from "lodash";
import { Postsuizhengyongyaoguilv } from "@/request/analysis";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Zhengzhuangteyixingfenxi extends Vue {
  @Prop()
  private report: any;
  @Prop()
  private config: any;
  private result: any = {};
  private ifShowFloatTab: boolean = false;
  private guilvLoaded: boolean = false;
  private guilvOption: any = {
    series: [
      {
        type: "graph",
        layout: "circular",
        roam: "true",
        label: {
          show: true,
        },
      },
    ],
  };
  @Watch("report", { immediate: true, deep: true })
  private reportChange() {
    this.result = this.report;
    this.createGuilv();
  }
  /**
   * @description 条件改变后重新请求，不重置条件
   */
  private conditionChange() {
    const load = this.$loading({
      text: "刷新中...请稍后",
    });
    const params: any = this.config;
    params.is_download = false;
    params["置信度"] = Number(params["置信度"]);
    params["支持度"] = Number(params["支持度"]);
    Postsuizhengyongyaoguilv(this, this.config).then((data: any) => {
      load.close();
      this.result = data;
      this.createGuilv();
    });
  }
  /**
   * @description 监听根部滚动
   */
  private handleScroll() {
    const top = (this.$refs.root as any).scrollTop;
    if (top && top > 200) {
      this.ifShowFloatTab = true;
    } else {
      this.ifShowFloatTab = false;
    }
  }
  /**
   * @description 导出数据
   */
  private downloadGuilv(name: any) {
    const load = this.$loading({
      text: "下载中...请稍后",
    });
    const params: any = this.config;
    params.is_download = true;
    params.download_name = name;
    params["置信度"] = Number(params["置信度"]);
    params["支持度"] = Number(params["支持度"]);
    Postsuizhengyongyaoguilv(this, this.config).then((data: any) => {
      window.open(
        window.API_ROOT + "api/wajue/download?filename=" + data.file_name
      );
      load.close();
    });
  }
  /**
   * @description 生成随症用药规律关系图
   */
  private createGuilv() {
    this.guilvOption = {
      series: [
        {
          type: "graph",
          layout: "circular",
          roam: "true",
          label: {
            show: true,
          },
        },
      ],
    };
    this.guilvOption.series[0].data = this.result["随症加减规律"]["nodes"];
    this.guilvOption.series[0].categories =
      this.result["随症加减规律"]["categories"];
    this.guilvOption.series[0].links = this.result["随症加减规律"]["links"];
    this.guilvOption.series[0].symbolSize = (value: any) => {
      return value;
    };
    this.guilvLoaded = true;
  }
  /**
   * @description 初始化
   */
  private mounted() {
    // this.result = this.report;
    // this.createGuilv();
  }
}
