















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as _ from "lodash";
import { Postzhengzhuangteyixingfenxi } from "@/request/analysis";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Zhengzhuangteyixingfenxi extends Vue {
  @Prop()
  private report: any;
  @Prop()
  private config: any;
  private result: any = {};
  private ifShowFloatTab: boolean = false;
  private sankeyLoaded: boolean = false;
  private teyixingOption: any = {
    series: [
      {
        type: "sankey",
        layout: "none",
        focusNodeAdjacency: "allEdges",
      },
    ],
  };
  @Watch("report", { immediate: true, deep: true })
  private reportChange() {
    this.result = this.report;
    this.createSankey();
  }
  /**
   * @description 监听根部滚动
   */
  private handleScroll() {
    const top = (this.$refs.root as any).scrollTop;
    if (top && top > 200) {
      this.ifShowFloatTab = true;
    } else {
      this.ifShowFloatTab = false;
    }
  }
  /**
   * @description 生成桑基图
   */
  private createSankey() {
    this.teyixingOption.series[0].data = this.result["症状特异性分析"]["nodes"];
    this.teyixingOption.series[0].links =
      this.result["症状特异性分析"]["links"];
    this.sankeyLoaded = true;
  }
  /**
   * @description 特异性导出数据
   */
  private downloadTeyixing(name: any) {
    const load = this.$loading({
      text: "下载中...请稍后",
    });
    const params: any = this.config;
    params.is_download = true;
    params.download_name = name;
    Postzhengzhuangteyixingfenxi(this, this.config).then((data: any) => {
      window.open(
        window.API_ROOT + "api/wajue/download?filename=" + data.file_name
      );
      load.close();
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    // this.result = this.report;
    // this.createSankey();
  }
}
