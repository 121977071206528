var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"root",staticClass:"reportContent commonReport",attrs:{"id":"zhengzhuangqunfenxi"},on:{"scroll":_vm.handleScroll}},[_c('div',{staticClass:"part"},[_c('div',{staticClass:"title"},[_vm._v("变量设置")]),_c('div',{staticClass:"line"},[_vm._v("分析变量："+_vm._s(_vm.result["分析变量"]))]),_c('div',{staticClass:"line"},[_vm._v("分组变量："+_vm._s(_vm.result["分组变量"]))])]),_c('div',{staticClass:"part"},[_c('div',{staticClass:"title"},[_vm._v("分析方法")]),_c('div',{staticClass:"line"},[_vm._v(" "+_vm._s(_vm.result["分析方法"])+" ")])]),_c('div',{staticClass:"part",staticStyle:{"border-top":"1px dashed #d0d0d0","padding-top":"20px"}},[_c('div',{staticClass:"title"},[_vm._v("结果解释")]),_c('div',{staticClass:"zhengzhuangqunPart"},[_c('div',{staticClass:"partWrap echartsWrap"},[_c('div',{staticClass:"part cloudWrap"},[_c('div',{staticClass:"partTitle"},[_vm._v("症状群分布")]),(_vm.cloudLoaded)?_c('wordcloud',{attrs:{"data":_vm.result['症状群分布']['data'],"color":_vm.blueColor,"nameKey":"name","valueKey":"value","rotate":{ from: 0, to: 0 },"fontSize":[10, 40]}}):_vm._e()],1),_c('div',{staticClass:"part graphWrap"},[_c('div',{staticClass:"partTitle"},[_vm._v("证-症图谱")]),(
              _vm.graphLoaded &&
              _vm.result['症证图谱'] &&
              _vm.result['症证图谱']['nodes'] &&
              _vm.result['症证图谱']['nodes'].length > 0
            )?_c('VueEcharts',{staticClass:"tupu",attrs:{"option":_vm.tupuOption}}):_vm._e(),(
              !_vm.result['症证图谱'] ||
              !_vm.result['症证图谱']['nodes'] ||
              _vm.result['症证图谱']['nodes'].length == 0
            )?_c('Empty',{attrs:{"text":'暂无数据!'}}):_vm._e()],1)]),(_vm.result['症状统计详情'])?_c('div',{staticClass:"tableWrap"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"partTitle"},[_vm._v(" 症状统计详情 "),(_vm.result['症状统计详情'])?_c('span',[_vm._v("（共计"+_vm._s(_vm.result["症状统计详情"]["count"])+"条，可预览前20条）")]):_vm._e()]),_c('button',{staticClass:"exportBtn",on:{"click":_vm.downloadZhengzhuangqun}},[_c('img',{attrs:{"src":"/analysis/导出数据.png","alt":""}}),_c('span',[_vm._v("导出数据")])])]),_c('table',{attrs:{"border":"1"}},[_vm._m(0),_c('tbody',_vm._l((_vm.result['症状统计详情']['data']),function(item,itemIndex){return _c('tr',{key:itemIndex},[_c('td',[_vm._v(_vm._s(itemIndex + 1))]),_vm._l((item),function(i){return _c('td',{key:i},[_vm._v(_vm._s(i))])})],2)}),0)])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("序号")]),_c('th',[_vm._v("证型")]),_c('th',[_vm._v("症状")]),_c('th',[_vm._v("频次")]),_c('th',[_vm._v("百分比")])])])}]

export { render, staticRenderFns }