
















































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as _ from "lodash";
import { Postyongyaopeiwufenxi } from "@/request/analysis";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Zhengzhuangteyixingfenxi extends Vue {
  @Prop()
  private report: any;
  @Prop()
  private config: any;
  private result: any = {};
  private ifShowFloatTab: boolean = false;
  private numberCount: any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  private pinciLoaded: boolean = false;
  private pinciOption: any = {
    legend: {
      right: "28%",
      top: 50,
      orient: "vertical",
      data: [],
      itemGap: 30,
      icon: "circle",
    },
    color: ["#74A0FA", "#75DDB5", "#7784A2", "#F8C53C", "#EC7E68"],
    series: [
      {
        type: "pie",
        radius: ["0%", "80%"],
        center: ["30%", "50%"],
      },
    ],
  };
  private guanlianguizeLoaded: boolean = false;
  private guanlianguizeOption: any = {
    color: ["#FF8E19"],
    series: [
      {
        type: "graph",
        layout: "force",
        force: {
          repulsion: 1000,
        },
        roam: true,
        label: {
          show: true,
        },
      },
    ],
  };
  @Watch("report", { immediate: true, deep: true })
  private reportChange() {
    this.result = this.report;
    this.createPinci();
    this.createGuanlianguize();
  }
  /**
   * @description 条件改变后重新请求，不重置条件
   */
  private conditionChange() {
    const load = this.$loading({
      text: "刷新中...请稍后",
    });
    const params: any = this.config;
    params.is_download = false;
    params["置信度"] = Number(params["置信度"]);
    params["支持度"] = Number(params["支持度"]);
    Postyongyaopeiwufenxi(this, this.config).then((data: any) => {
      load.close();
      this.result = data;
      this.createPinci();
      this.createGuanlianguize();
    });
  }
  /**
   * @description 监听根部滚动
   */
  private handleScroll() {
    const top = (this.$refs.root as any).scrollTop;
    if (top && top > 200) {
      this.ifShowFloatTab = true;
    } else {
      this.ifShowFloatTab = false;
    }
  }
  /**
   * @description 导出数据
   */
  private downloadPinci(name: any) {
    const load = this.$loading({
      text: "下载中...请稍后",
    });
    const params: any = this.config;
    params.is_download = true;
    params.download_name = name;
    params["置信度"] = Number(params["置信度"]);
    params["支持度"] = Number(params["支持度"]);
    Postyongyaopeiwufenxi(this, this.config).then((data: any) => {
      window.open(
        window.API_ROOT + "api/wajue/download?filename=" + data.file_name
      );
      load.close();
    });
  }
  /**
   * @description 生成用药频次饼图
   */
  private createPinci() {
    this.pinciOption.legend.data = this.result["用药频次"].name;
    this.pinciOption.series[0].data = this.result["用药频次"].data;
    this.pinciLoaded = true;
  }
  /**
   * @description 生成药物组合的关联规则分析关系图
   */
  private createGuanlianguize() {
    this.guanlianguizeOption = {
      color: ["#FF8E19"],
      series: [
        {
          type: "graph",
          layout: "force",
          force: {
            repulsion: 1000,
          },
          roam: true,
          label: {
            show: true,
          },
        },
      ],
    };
    this.guanlianguizeOption.series[0].data =
      this.result["药物组合的关联规则分析"]["nodes"];
    this.guanlianguizeOption.series[0].categories =
      this.result["药物组合的关联规则分析"]["categories"];
    this.guanlianguizeOption.series[0].links =
      this.result["药物组合的关联规则分析"]["links"];
    this.guanlianguizeOption.series[0].symbolSize = (value: any) => {
      return value;
    };
    this.guanlianguizeLoaded = true;
  }
  /**
   * @description 初始化
   */
  private mounted() {
    // this.result = this.report;
    // this.createPinci();
    // this.createGuanlianguize();
  }
}
