












































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as _ from "lodash";
import { Postyongyaoteyixingfenxi } from "@/request/analysis";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Zhengzhuangteyixingfenxi extends Vue {
  @Prop()
  private report: any;
  @Prop()
  private config: any;
  private result: any = {};
  private ifShowFloatTab: boolean = false;
  private teyixingLoaded: boolean = false;
  private teyixingOption: any = {
    color: ["#547CFE", "#87A3FE", "#C3D1FF"],
    series: [
      {
        radius: ["15%", "80%"],
        type: "sunburst",
        sort: null,
        highlightPolicy: "ancestor",
        label: {
          color: "#fff",
        },
        levels: [
          {},
          {
            itemStyle: {
              color: "#547CFE",
            },
            label: {
              rotate: "radial",
            },
          },
          {
            itemStyle: {
              color: "#87A3FE",
            },
            label: {
              rotate: "tangential",
            },
          },
          {
            itemStyle: {
              color: "#C3D1FF",
            },
            label: {
              rotate: 0,
            },
          },
        ],
      },
    ],
  };
  @Watch("report", { immediate: true, deep: true })
  private reportChange() {
    this.result = this.report;
    this.createTeyixing();
  }
  /**
   * @description 监听根部滚动
   */
  private handleScroll() {
    const top = (this.$refs.root as any).scrollTop;
    if (top && top > 200) {
      this.ifShowFloatTab = true;
    } else {
      this.ifShowFloatTab = false;
    }
  }
  /**
   * @description 导出数据
   */
  private downloadTeyixing(name: any) {
    const load = this.$loading({
      text: "下载中...请稍后",
    });
    const params: any = this.config;
    params.is_download = true;
    params.download_name = name;
    Postyongyaoteyixingfenxi(this, this.config).then((data: any) => {
      window.open(
        window.API_ROOT + "api/wajue/download?filename=" + data.file_name
      );
      load.close();
    });
  }
  /**
   * @description 生成特异性饼图
   */
  private createTeyixing() {
    this.teyixingOption.series[0].data = this.result["用药特异性分析"];
    this.teyixingLoaded = true;
  }
  /**
   * @description 初始化
   */
  private mounted() {
    // this.result = this.report;
    // this.createTeyixing();
  }
}
